/* @flow */
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { useMutation } from "@apollo/react-hooks";
import invariant from "invariant";
import qs from "qs";
import moment from "moment-timezone";
import getComboButtonOptions, {
  type ComposerPersistActionType
} from "./getComboButtonOptions";
import getRequiredConfirmations, {
  type ConfirmationText
} from "./getRequiredConfirmations";
import ConfirmationModal from "layout/modals/Confirmation";
import OnboardingTooltip from "components/common/OnboardingTooltip";
import SetSendAtModal from "./SetSendAtModal";
import ConnectAccountsModal from "./ConnectAccountsModal";
//import ImportContentModal from "./ImportContentModal";
import SeeMorePendingContentDialog from "./SeeMorePendingContentDialog";
import ActionsButton from "./ActionsButton";
import CancelLink from "./CancelLink";
import DeleteButton from "./DeleteButton";
import styles from "./index.css";

import { selectPrepareSeeMorePendingContentDialog } from "selectors/onboarding";

import UPDATE_ONBOARDING from "mutations/updateOnboarding";

import type { AppState, Connector } from "types";
import type { Location, RouterHistory } from "react-router-dom";
import type {
  loadContent_content as ContentData,
  ContentStatus
} from "graphql-types/loadContent";

type Props = {
  content: ContentData,
  isSubmittable: boolean,
  isPersisting: boolean,
  isSetSendAtModalOpen: boolean,
  isConnectAccountsModalOpen: boolean,
  prepareSeeMorePendingContentDialog: boolean,
  showOnboardingTooltip: boolean,
  showImportContentModal: boolean,
  location: Location,
  history: RouterHistory,
  timeZone: string,
  onPersist: () => void,
  onOpenSetSendAtModal: () => void,
  onCloseSetSendAtModal: () => void,
  onFinishSetSendAtModal: (sendAt: string) => void,
  onPersistAndSendNow: () => void,
  onApprovePersistAndSendNow: () => void,
  onApproveAndPersist: () => void,
  onApproveAndOpenSetSendAtModal: () => void,
  onChangeStatus: (status: ContentStatus) => void,
  onChangeSendAt: (sendAt: string) => void,
  updateOnboarding: (variables: Object) => void
};

type State = {
  initialContentStatus: ContentStatus,
  confirmations: ConfirmationText[],
  deferredActionType: ?ComposerPersistActionType,
  sendAtWasInPastAtMount: boolean,
  showSeeMorePendingContentDialog: boolean
};

export const TOOLTIP_BODY =
  "Save your content. Or click the drop down button to schedule your content manually.";

export class Actions extends Component<Props, State> {
  state = {
    deferredActionType: null,
    confirmations: [],
    // Only existing content can require approval and edits always occur in a freshly mounted composer
    // so we can use the initialContentStatus from when component is constructed to prevent the actions
    // from changing to the approved state mid approval.
    initialContentStatus: this.props.content.status,
    sendAtWasInPastAtMount: this.props.content.sendAt
      ? moment()
          .tz(this.props.timeZone)
          .isAfter(moment(this.props.content.sendAt).tz(this.props.timeZone))
      : false,
    showSeeMorePendingContentDialog: false
  };

  componentDidMount = () => {
    const queryParams = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true
    });

    if (queryParams.see_more_pending_content) {
      setTimeout(() => {
        this.setState({ showSeeMorePendingContentDialog: true });
        this.removeSeeMorePendingContentQueryParam(queryParams);
      }, 500);
    }
  };

  attemptAction = (actionType: ComposerPersistActionType) => {
    // This query param check needs to happen before confirmations check below
    const isPendingContentAction = actionType.includes("APPROVE_");

    if (
      this.props.prepareSeeMorePendingContentDialog &&
      isPendingContentAction
    ) {
      this.addSeeMorePendingContentQueryParam();
    }

    const confirmations = getRequiredConfirmations(
      this.props.content,
      actionType
    );

    // If content state requires confirmations ensure they are acknowledged before performing action
    if (!!confirmations.length) {
      this.setState({
        deferredActionType: actionType,
        confirmations
      });
      return;
    }

    this.getActionByType(actionType)();
    this.props.updateOnboarding({ variables: { stepName: "add_content" } });
  };

  handleConfirmPersist = () => {
    const [_confirmed, ...remaining] = this.state.confirmations;

    this.setState({ confirmations: remaining }, () => {
      const { confirmations, deferredActionType } = this.state;
      if (!!confirmations.length) return;
      invariant(
        !!deferredActionType,
        "Expected deferredActionType to be present."
      );
      this.getActionByType(deferredActionType)();
      this.setState({ deferredActionType: null });
    });
  };

  handleCancelPersist = () => {
    if (
      this.state.deferredActionType === "APPROVE_AND_OPEN_SET_SEND_AT_MODAL"
    ) {
      this.props.onChangeStatus(this.state.initialContentStatus);
    }
    this.setState({ deferredActionType: null, confirmations: [] });
  };

  onCloseSeeMorePendingContentDialog = () => {
    this.setState({ showSeeMorePendingContentDialog: false });
  };

  addSeeMorePendingContentQueryParam = () => {
    const queryParams = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true
    });
    queryParams.see_more_pending_content = true;

    this.props.history.push({
      search: qs.stringify(queryParams)
    });
  };

  removeSeeMorePendingContentQueryParam = (queryParams: Object) => {
    delete queryParams.see_more_pending_content;

    this.props.history.replace({
      search: qs.stringify(queryParams)
    });
  };

  getActionByType = (actionType: ComposerPersistActionType) => {
    const ACTIONS_BY_ACTION_TYPE = {
      PERSIST: this.props.onPersist,
      PERSIST_AND_SEND_NOW: this.props.onPersistAndSendNow,
      OPEN_SET_SEND_AT_MODAL: this.props.onOpenSetSendAtModal,
      APPROVE_AND_PERSIST: this.props.onApproveAndPersist,
      APPROVE_PERSIST_AND_SEND_NOW: this.props.onApprovePersistAndSendNow,
      APPROVE_AND_OPEN_SET_SEND_AT_MODAL: this.props
        .onApproveAndOpenSetSendAtModal
    };
    return ACTIONS_BY_ACTION_TYPE[actionType];
  };

  getComboButtonTooltip = (comboButtonDisabled: boolean) =>
    !comboButtonDisabled && this.props.showOnboardingTooltip ? (
      <OnboardingTooltip tooltipBody={TOOLTIP_BODY} />
    ) : null;

  render() {
    const {
      content: { id, sendAt },
      isSubmittable,
      isPersisting,
      isSetSendAtModalOpen,
      isConnectAccountsModalOpen,
      timeZone,
      onChangeSendAt,
      onFinishSetSendAtModal,
      onCloseSetSendAtModal
    } = this.props;

    const {
      confirmations,
      initialContentStatus,
      sendAtWasInPastAtMount,
      showSeeMorePendingContentDialog
    } = this.state;

    const comboButtonDisabled = !isSubmittable || isPersisting;
    const isNewContent = !id;

    const { primaryOptions, secondaryOptions } = getComboButtonOptions({
      contentRequiresApproval: !initialContentStatus.match("APPROVED|EXPIRED"),
      isNewContent,
      sendAt,
      sendAtWasInPastAtMount,
      loading: isPersisting,
      onAction: this.attemptAction.bind(this)
    });

    const tooltip = this.getComboButtonTooltip(comboButtonDisabled);

    return (
      <div className={styles.root}>
        {isConnectAccountsModalOpen && <ConnectAccountsModal />}
        {/*!isConnectAccountsModalOpen && showImportContentModal && (
          <ImportContentModal />
        )*/}
        {showSeeMorePendingContentDialog && (
          <SeeMorePendingContentDialog
            onClose={this.onCloseSeeMorePendingContentDialog}
          />
        )}

        {isNewContent ? null : (
          <DeleteButton disabled={isPersisting} contentId={id} />
        )}

        {!!confirmations.length && (
          <ConfirmationModal
            title={confirmations[0].title}
            subTitle={confirmations[0].subTitle}
            confirmLabel="Yes, Save"
            type="warning"
            onConfirm={this.handleConfirmPersist}
            onCancel={this.handleCancelPersist}
            category={confirmations[0].category}
            text={confirmations[0].text}
          />
        )}
        {!!isSetSendAtModalOpen && (
          <SetSendAtModal
            timeZone={timeZone}
            showWarning={false}
            isEditing={false}
            onChangeSendAt={onChangeSendAt}
            onFinish={onFinishSetSendAtModal}
            onCancelSetSendAtModal={onCloseSetSendAtModal}
          />
        )}

        <CancelLink disabled={isPersisting} />

        <div className={styles.saveContainer}>
          {tooltip}
          <ActionsButton
            label="Save"
            options={primaryOptions}
            type="primary"
            loading={primaryOptions[0].loading}
            disabled={comboButtonDisabled || primaryOptions[0].loading}
          />
        </div>

        <ActionsButton
          label=""
          options={secondaryOptions}
          type="primary"
          loading={false}
          disabled={comboButtonDisabled}
        />
      </div>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  prepareSeeMorePendingContentDialog: selectPrepareSeeMorePendingContentDialog(
    state
  )
});

const connector: Connector<{}, Props> = connect(mapStateToProps);

export const ActionsContainer = withRouter(connector(Actions));

export default (props: Props) => {
  // Mutations
  const [updateOnboarding, {}] = useMutation(UPDATE_ONBOARDING);

  return <ActionsContainer {...props} updateOnboarding={updateOnboarding} />;
};
