/* @flow */
import React, { type Node } from "react";
import classNames from "classnames";
import Link from "links/Link";
import { LoadingIcon } from "icons";
import styles from "./index.css";
import { type ConfirmType } from "layout/modals/Confirmation";
import { type HttpMethod } from "types";

export type ButtonType =
  | "primary"
  | "secondary"
  | "tint"
  | "primary-negative"
  | "secondary-negative"
  | "inline"
  | "light";

type Props = {
  submit?: boolean,
  method?: HttpMethod,
  disabled?: boolean,
  loading?: boolean,
  type?: ButtonType,
  className?: string,
  target?: string,
  tabIndex: string,
  confirm?: boolean,
  confirmType?: ConfirmType,
  confirmMessage?: string,
  confirmTitle?: string,
  confirmLabel?: string,
  url?: string, // TODO: deprecate in favor of href
  href?: string,
  small?: boolean,
  thin?: boolean,
  enforceMinWidth?: boolean,
  dataTest?: string,
  onClick?: EventHandler,
  onMouseDown?: EventHandler,
  children?: Node,
  block?: boolean
};

const Button = ({
  submit = false,
  target,
  confirm = false,
  confirmType,
  confirmMessage,
  confirmTitle,
  confirmLabel,
  url,
  href,
  tabIndex,
  method = "GET",
  disabled = false,
  loading = false,
  type,
  small = false,
  thin = false,
  enforceMinWidth = true,
  className,
  children,
  dataTest = "",
  onClick,
  block
}: Props) => {
  const classes = classNames(styles.root, className, {
    [styles[type || ""]]: !!type,
    [styles.small]: small,
    [styles.thin]: thin,
    [styles.enforceMinWidth]: enforceMinWidth,
    [styles.loading]: !!loading,
    [styles.block]: !!block
  });

  const loadingIcon = loading ? (
    <LoadingIcon className={styles.spinner} />
  ) : null;

  if (submit) {
    return (
      <button
        type="submit"
        tabIndex={tabIndex}
        disabled={disabled || loading}
        className={classes}
        data-test={dataTest}
        onClick={onClick}
      >
        {loadingIcon}
        <span className={styles.childrenContainer}>{children}</span>
      </button>
    );
  }

  return (
    <Link
      href={url || href}
      tabIndex={tabIndex}
      confirm={confirm}
      confirmType={confirmType}
      confirmTitle={confirmTitle}
      confirmMessage={confirmMessage}
      confirmLabel={confirmLabel}
      method={method}
      disabled={disabled || loading}
      className={classes}
      target={target}
      dataTest={dataTest}
      onClick={onClick}
    >
      {loadingIcon}
      <span className={styles.childrenContainer}>{children}</span>
    </Link>
  );
};

export default Button;
