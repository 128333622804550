/* @flow */
import { produce } from "immer";
import { omit, isNil, uniq } from "lodash";
import { nanoid } from "nanoid";
import { extractUrlsWithIndices } from "twitter-text";

import validatePlatformCapabilities from "lib/content/validatePlatformCapabilities";
import validateServer from "./util/serverValidation";
import { platformsSupportLinkPreview } from "./util.js";
import { isValidUrl, urlsEqual } from "util";

import type {
  loadContent_content as ContentData,
  loadContent_content_variations_images as ImageData,
  loadContent_content_variations_videos as VideoData,
  loadContent_content_variations_pageScrape as PageScrapeData
} from "graphql-types/loadContent";
import type { loadComposer_categories as CategoryData } from "graphql-types/loadComposer";
import type { loadComposer_accounts as AccountData } from "graphql-types/loadComposer";
import type { getVariationSuggestions_variationSuggestions as VariationSuggestionData } from "graphql-types/getVariationSuggestions";
import type { searchSocialMedia_searchSocialMedia as SocialMediaSearchResultData } from "graphql-types/searchSocialMedia";
import type { ServerError, ServerErrorsByField } from "./util/serverValidation";

type RejectionModalReasons = {
  incompatibleAccountReasons: string[],
  incompatibleMediaReasons: { [key: string]: string[] },
  failedValidationReasons: string[]
};

type EditorField = "text" | "fbText";
type EditorFocus = {
  field: EditorField,
  idx: number,
  hovered: ?number
};

type MediaUpload = { fileName: string, progress: ?number };
type UploadingMediaState = { [key: string]: MediaUpload[] };

type OnboardingTooltipStep =
  | "accounts"
  | "categories"
  | "textfield"
  | "add-variation"
  | "save";

type ScrapeProgress = {
  [key: string]: {
    status: "QUEUED" | "STARTED" | "POLLING" | "FAILED" | "SUCCEEDED",
    url: string,
    timeoutId: ?TimeoutID,
    pageScrape: ?PageScrapeData,
    errors: ?string
  }
};

type State = {
  content: ?ContentData,
  categories: CategoryData[],
  accounts: AccountData[],
  serverErrors: ServerErrorsByField,
  variationSuggestions: { status: "COMPLETE" | "FAILED" | null },
  mentionSuggestions: SocialMediaSearchResultData[],
  uploadingMedia: UploadingMediaState,
  attachingImage: { [key: string]: boolean },
  scrapes: ScrapeProgress,
  editorFocus: EditorFocus,
  rejectionModalReasons: RejectionModalReasons,
  onboardingTooltipStep: ?OnboardingTooltipStep,
  persistResult: "CREATED" | "UPDATED" | "ERROR" | null,
  linkAttachmentRemoved: { [key: string]: boolean },
  hasModifiedVariations: boolean,
  isSetSendAtModalOpen: boolean,
  loadedContent: boolean,
  hasAccountWithoutSchedule?: boolean
};

type LoadComposerAction = {
  type: "LOAD_COMPOSER",
  categories: CategoryData[],
  accounts: AccountData[]
};

type LoadWidgetAction = {
  type: "LOAD_WIDGET"
};

type LoadCategoriesAction = {
  type: "LOAD_CATEGORIES",
  categories: CategoryData[]
};

type LoadContentAction = {
  type: "LOAD_CONTENT",
  content: ContentData
};

type UpdateContentFinishAction = {
  type: "UPDATE_CONTENT_FINISH",
  content: ?ContentData,
  errors: ServerError[]
};

type CreateContentFinishAction = {
  type: "CREATE_CONTENT_FINISH",
  content: ?ContentData,
  errors: ServerError[],
  hasAccountWithoutSchedule: boolean
};

type StartPersistAction = {
  type: "START_PERSIST"
};

type FinishPersistAction = {
  type: "FINISH_PERSIST"
};

type SelectCategoryAction = {
  type: "SELECT_CATEGORY",
  categoryId: string | null
};

type SelectAccountAction = {
  type: "SELECT_ACCOUNT",
  accountId: string
};

type DeselectAccountAction = {
  type: "DESELECT_ACCOUNT",
  accountId: string
};

type SelectPinterestBoardAction = {
  type: "SELECT_PINTEREST_BOARD",
  accountId: string,
  pinterestBoardId: string
};

type SelectLiAdAccountAction = {
  type: "SELECT_LINKEDIN_AD_ACCOUNT",
  accountId: string,
  liAdAccountId: string
};

type ChangeInstagramBusinessSettingAction = {
  type: "CHANGE_INSTAGRAM_BUSINESS_SETTING",
  sendMobileReminder: boolean,
  instaReels?: boolean
};

type ChangeLinkedInPostTypeAction = {
  type: "CHANGE_LINKEDIN_POST_TYPE",
  linkedinCarousel?: boolean
};

type ChangeUseOnceAction = {
  type: "CHANGE_USE_ONCE",
  useOnce: boolean
};

type ChangeUseShortLinksAction = {
  type: "CHANGE_USE_SHORT_LINKS",
  useShortLinks: boolean
};

type ChangeExpiresAtAction = {
  type: "CHANGE_EXPIRES_AT",
  expiresAt: ?string
};

type ChangeSendAtAction = {
  type: "CHANGE_SEND_AT",
  sendAt: ?string
};

type ApproveAction = {
  type: "APPROVE"
};

type FocusEditorAction = {
  type: "FOCUS_EDITOR",
  idx: ?number,
  field: EditorField
};

type BlurEditorAction = {
  type: "BLUR_EDITOR"
};

type MouseMoveEditorAction = {
  type: "MOUSE_MOVE_EDITOR",
  idx: number
};

type MouseLeaveEditorAction = {
  type: "MOUSE_LEAVE_EDITOR",
  idx: number
};

type ChangePinterestDestinationLinkAction = {
  type: "CHANGE_PINTEREST_DESTINATION_LINK",
  variationClientId: string,
  link: ?string
};

type ChangePinterestTitleAction = {
  type: "CHANGE_PINTEREST_TITLE",
  variationClientId: string,
  title: string
};

type ChangeFbTextAction = {
  type: "CHANGE_FB_TEXT",
  variationClientId: string,
  fbText: string
};

type ChangeTextAction = {
  type: "CHANGE_TEXT",
  variationClientId: string,
  text: string
};

type ChangeAccountTextAction = {
  type: "CHANGE_ACCOUNT_TEXT",
  variationClientId: string,
  accountId: string,
  text?: string,
  removeFromUpdate?: boolean
};

type ChangeEntityMapAction = {
  type: "CHANGE_ENTITY_MAP",
  variationClientId: string,
  rawRichTextEntityMap: Object
};

type AddVariationAction = {
  type: "ADD_VARIATION"
};

type DeleteVariationAction = {
  type: "DELETE_VARIATION",
  variationClientId: string
};

type AddSuggestedVariationsAction = {
  type: "ADD_SUGGESTED_VARIATIONS",
  suggestions: VariationSuggestionData[]
};

type DismissSuggestedVariationsFailedAction = {
  type: "DISMISS_SUGGESTED_VARIATIONS_FAILED"
};

type ImageUploadProgressAction = {
  type: "IMAGE_UPLOAD_PROGRESS",
  variationClientId: string,
  fileName: string,
  progress: ?number
};

type ImageUploadErrorAction = {
  type: "IMAGE_UPLOAD_ERROR",
  variationClientId: string,
  fileName: string,
  messages: string[]
};

type ImageUploadFinishAction = {
  type: "IMAGE_UPLOAD_FINISH",
  variationClientId: string,
  fileName: string,
  image: ImageData
};

type ChangeImageOrderAction = {
  type: "CHANGE_IMAGE_ORDER_ACTION",
  variationClientId: string,
  images: []
};

type ChangeImageTitleAction = {
  type: "CHANGE_IMAGE_TEXT_ACTION",
  variationClientId: string,
  images: []
};

type AttachVideoAction = {
  type: "ATTACH_VIDEO",
  variationClientId: string,
  video: VideoData
};

type RemoveImageAction = {
  type: "REMOVE_IMAGE",
  variationClientId: string,
  imageId: string
};

type VideoUploadProgressAction = {
  type: "VIDEO_UPLOAD_PROGRESS",
  variationClientId: string,
  fileName: string,
  progress: ?number
};

type VideoUploadErrorAction = {
  type: "VIDEO_UPLOAD_ERROR",
  variationClientId: string,
  fileName: string,
  messages: string[]
};

type VideoUploadFinishAction = {
  type: "VIDEO_UPLOAD_FINISH",
  variationClientId: string,
  fileName: string,
  video: VideoData
};

type RemoveVideoAction = {
  type: "REMOVE_VIDEO",
  variationClientId: string,
  videoId: string
};

type AttachImageAction = {
  type: "ATTACH_IMAGE",
  variationClientId: string,
  image: ImageData
};

type AttachScrapedImageStartAction = {
  type: "ATTACH_SCRAPED_IMAGE_START",
  variationClientId: string
};

type AttachScrapedImageFinishAction = {
  type: "ATTACH_SCRAPED_IMAGE_FINISH",
  variationClientId: string,
  image: ImageData
};

type SetMentionSuggestionsAction = {
  type: "SET_MENTION_SUGGESTIONS",
  suggestions: SocialMediaSearchResultData[]
};

type AttachLinkAction = {
  type: "ATTACH_LINK",
  variationClientId: string
};

type RemoveLinkAction = {
  type: "REMOVE_LINK",
  variationClientId: string
};

type DismissRejectionModalAction = {
  type: "DISMISS_REJECTION_MODAL"
};

type CompleteOnboardingTooltipStepAction = {
  type: "COMPLETE_ONBOARDING_TOOLTIP_STEP",
  step: OnboardingTooltipStep
};

type OpenSendAtModalAction = {
  type: "OPEN_SEND_AT_MODAL"
};

type CloseSendAtModalAction = {
  type: "CLOSE_SEND_AT_MODAL"
};

type StartScrapeAction = {
  type: "START_SCRAPE",
  variationClientId: string
};

type PollScrapeAction = {
  type: "POLL_SCRAPE",
  variationClientId: string,
  timeoutId: TimeoutID
};

type ScrapeFailedAction = {
  type: "SCRAPE_FAILED",
  pageScrape: PageScrapeData,
  variationClientId: string,
  errors: string[]
};

type ScrapeSucceededAction = {
  type: "SCRAPE_SUCCEEDED",
  variationClientId: string,
  pageScrape: PageScrapeData
};

type Action =
  | LoadComposerAction
  | LoadWidgetAction
  | LoadCategoriesAction
  | LoadContentAction
  | SelectCategoryAction
  | DeselectAccountAction
  | SelectAccountAction
  | SelectPinterestBoardAction
  | SelectLiAdAccountAction
  | ChangeInstagramBusinessSettingAction
  | ChangeUseOnceAction
  | ChangeUseShortLinksAction
  | ChangeExpiresAtAction
  | ChangeSendAtAction
  | ApproveAction
  | FocusEditorAction
  | BlurEditorAction
  | MouseMoveEditorAction
  | MouseLeaveEditorAction
  | ChangePinterestDestinationLinkAction
  | ChangePinterestTitleAction
  | ChangeFbTextAction
  | ChangeTextAction
  | ChangeAccountTextAction
  | ChangeEntityMapAction
  | AddVariationAction
  | DeleteVariationAction
  | AddSuggestedVariationsAction
  | DismissSuggestedVariationsFailedAction
  | ImageUploadProgressAction
  | ImageUploadErrorAction
  | ImageUploadFinishAction
  | ChangeImageOrderAction
  | ChangeImageTitleAction
  | RemoveImageAction
  | VideoUploadProgressAction
  | VideoUploadErrorAction
  | VideoUploadFinishAction
  | RemoveVideoAction
  | AttachVideoAction
  | AttachImageAction
  | AttachScrapedImageStartAction
  | AttachScrapedImageFinishAction
  | SetMentionSuggestionsAction
  | AttachLinkAction
  | RemoveLinkAction
  | DismissRejectionModalAction
  | UpdateContentFinishAction
  | CreateContentFinishAction
  | StartPersistAction
  | FinishPersistAction
  | CompleteOnboardingTooltipStepAction
  | OpenSendAtModalAction
  | CloseSendAtModalAction
  | StartScrapeAction
  | PollScrapeAction
  | ScrapeFailedAction
  | ScrapeSucceededAction;

export const initialState = Object.freeze({
  accounts: [],
  categories: [],
  serverErrors: {},
  rejectionModalReasons: {
    incompatibleAccountReasons: [],
    incompatibleMediaReasons: {},
    failedValidationReasons: []
  },
  editorFocus: {
    idx: 0,
    field: "text",
    hovered: null
  },
  variationSuggestions: {
    status: null
  },
  mentionSuggestions: [],
  uploadingMedia: {},
  attachingImage: {},
  persistResult: null,
  scrapes: {},
  linkAttachmentRemoved: {},
  content: {
    __typename: "Content",
    id: "",
    accountRelationships: [],
    category: null,
    expiresAt: null,
    sendAt: null,
    status: "APPROVED",
    totalPostCount: 0,
    twitterPostedVariationsCount: 0,
    useOnce: false,
    useShortLinks: true,
    sendMobileReminder: false,
    instaReels: false,
    linkedinCarousel: false,
    lastPostedTo: {
      __typename: "LastPostedTo",
      twitter: null
    },
    variations: [
      {
        __typename: "Variation",
        id: "",
        clientId: "1",
        accountsData: [],
        fbText: null,
        origin: "MANUAL",
        link: null,
        pageScrape: null,
        pinterestDestinationLink: null,
        pinterestTitle: null,
        rawRichTextEntityMap: null,
        text: "",
        images: [],
        videos: [],
        lastPostedTo: {
          __typename: "LastPostedTo",
          twitter: null
        }
      }
    ]
  },
  onboardingTooltipStep: "categories",
  hasModifiedVariations: false,
  isSetSendAtModalOpen: false,
  loadedContent: false
});

export const initialStateBuilder = (
  isWidget: boolean,
  variationText: ?string
) => (state: State) =>
    produce(state, draft => {
      if (isWidget) {
        draft.content.variations[0].origin = "WIDGET";
        draft.content.variations[0].text = variationText || "";
      }
    });

function maybeStartScrape(draft, variationClientId) {
  const variation = draft.content.variations.find(
    v => v.clientId === variationClientId
  );
  const { text, link, pinterestDestinationLink } = variation;
  const urlsInText = extractUrlsWithIndices(text).map(({ url }) => url);
  const urlToScrape = urlsInText?.[0] || link || pinterestDestinationLink;

  if (!urlToScrape || !isValidUrl(urlToScrape)) {
    return;
  }

  // These are still the same url and should not trigger a new scrape.
  const currentUrl = draft.scrapes[variationClientId]?.url;
  if (currentUrl && urlsEqual(currentUrl, urlToScrape)) {
    return;
  }

  draft.scrapes[variationClientId] = draft.scrapes[variationClientId] || {};
  draft.scrapes[variationClientId].status = "QUEUED";
}

export default (state: State, action: Action): State => {
  // DEBUG:
  //if (!action.type.match(/MOUSE|BLUR.*/)) {
  //  console.log(`dispatched: ${action.type}`, action);
  //}
  switch (action.type) {
    case "LOAD_COMPOSER": {
      const { categories, accounts } = action;
      return produce(state, draft => {
        draft.categories = categories;
        draft.accounts = accounts;
      });
    }

    case "LOAD_WIDGET": {
      return produce(state, draft => {
        (state.content?.variations ?? []).forEach(v => {
          draft.hasModifiedVariations = false;
          maybeStartScrape(draft, v.clientId);
        });
      });
    }

    case "LOAD_CATEGORIES": {
      const { categories } = action;
      return produce(state, draft => {
        draft.categories = categories;
      });
    }

    case "LOAD_CONTENT": {
      const { content } = action;

      const platforms = uniq(
        content.accountRelationships.map(a => a.account.platform)
      );
      return produce(state, draft => {
        draft.content = content;
        draft.loadedContent = true;
        draft.hasModifiedVariations = false;
        content.variations.forEach(v => {
          maybeStartScrape(draft, v.clientId);
          if (isNil(v.link) && platformsSupportLinkPreview(platforms)) {
            draft.linkAttachmentRemoved[v.clientId] = true;
          }
        });
        draft.editorFocus.idx = 0;
        draft.editorFocus.field = "text";
      });
    }

    case "SELECT_CATEGORY": {
      const { categoryId } = action;
      const selectedCategory = state.categories.find(c => c.id === categoryId);
      return produce(state, draft => {
        draft.content.category = selectedCategory;
        // Clear server errors related to category
        draft.serverErrors = omit(draft.serverErrors, "category_id");
      });
    }

    case "SELECT_ACCOUNT": {
      const { accountId } = action;
      const selectedAccount = state.accounts.find(a => a.id === accountId);
      if (!selectedAccount || !state.content) {
        return state;
      }

      const accountAlreadySelected = state.content.accountRelationships.some(
        r => r.account.id === accountId
      );
      if (accountAlreadySelected) {
        return state;
      }

      const nextState = produce(state, draft => {
        draft.content.accountRelationships.push({
          account: selectedAccount,
          pinterestBoards: [],
          linkedinAdAccounts: []
        });

        if (
          selectedAccount.provider === "INSTAGRAM_BUSINESS" && state.content
            ? state.content.variations.some(v => v.images.length > 1)
            : false
        ) {
          draft.content.sendMobileReminder = true;
        }
      });

      const { errors, valid } = validatePlatformCapabilities(nextState.content);
      if (!valid) {
        return produce(state, draft => {
          draft.rejectionModalReasons.incompatibleAccountReasons = errors;
        });
      }

      return nextState;
    }

    case "DESELECT_ACCOUNT": {
      const { accountId } = action;
      const deselectedAccount = state.accounts.find(a => a.id === accountId);
      if (!deselectedAccount || !state.content) {
        return state;
      }

      const accountNotSelected = !state.content.accountRelationships.some(
        r => r.account.id === accountId
      );
      if (accountNotSelected) {
        return state;
      }

      const nextState = produce(state, draft => {
        draft.content.accountRelationships = draft.content.accountRelationships.filter(
          r => r.account.id !== deselectedAccount.id
        );
      });

      const { errors, valid } = validatePlatformCapabilities(nextState.content);
      if (!valid) {
        return produce(state, draft => {
          draft.rejectionModalReasons.incompatibleAccountReasons = errors;
        });
      }

      return nextState;
    }

    case "SELECT_PINTEREST_BOARD": {
      const { accountId, pinterestBoardId } = action;
      const selectedAccount = state.accounts.find(a => a.id === accountId);
      if (!selectedAccount) {
        return state;
      }
      const selectedBoard = selectedAccount.pinterestBoards.find(
        b => b.id === pinterestBoardId
      );
      if (!selectedBoard || !state.content) {
        return state;
      }

      const accountIsNotSelected = !state.content.accountRelationships.some(
        r => r.account.id === accountId
      );
      if (accountIsNotSelected || !state.content) {
        return state;
      }

      const alreadySelected = state.content.accountRelationships.some(r =>
        r.pinterestBoards.some(p => p.id === pinterestBoardId)
      );
      if (alreadySelected) {
        return state;
      }

      const nextState = produce(state, draft => {
        const accountRelationship = draft.content.accountRelationships.find(
          r => r.account.id === accountId
        );
        // This supports a single board for now
        accountRelationship.pinterestBoards = [selectedBoard];
      });

      const { errors, valid } = validatePlatformCapabilities(nextState.content);
      if (!valid) {
        return produce(state, draft => {
          draft.rejectionModalReasons.incompatibleAccountReasons = errors;
        });
      }

      return nextState;
    }

    case "SELECT_LINKEDIN_AD_ACCOUNT": {
      const liAdAccountId = action["liAdAccountId"];
      const accountLiId = action["accountId"];

      const selectedLiCompanyAccount = state.accounts.find(
        a => a.id === accountLiId
      );
      if (!selectedLiCompanyAccount) {
        return state;
      }
      const selectedAdAccount = selectedLiCompanyAccount.linkedinAdAccounts.find(
        b => b.id === liAdAccountId
      );
      if (!selectedAdAccount || !state.content) {
        return state;
      }

      const accountIsNotSelected = !state.content.accountRelationships.some(
        r => r.account.id === accountLiId
      );
      if (accountIsNotSelected || !state.content) {
        return state;
      }

      const alreadySelected = state.content.accountRelationships.some(r =>
        (r.linkedinAdAccounts || []).some(p => p.id === liAdAccountId)
      );
      if (alreadySelected) {
        return state;
      }

      const nextLiState = produce(state, draft => {
        const accountRelationship = draft.content.accountRelationships.find(
          r => r.account.id === accountLiId
        );
        // This supports a single board for now
        accountRelationship.linkedinAdAccounts = [selectedAdAccount];
      });

      const { errors, valid } = validatePlatformCapabilities(
        nextLiState.content
      );
      if (!valid) {
        return produce(state, draft => {
          draft.rejectionModalReasons.incompatibleAccountReasons = errors;
        });
      }

      return nextLiState;
    }

    case "CHANGE_LINKEDIN_POST_TYPE": {
      const { linkedinCarousel, accountRelationship } = action;

      if (!state.content) {
        return state;
      }

      return produce(state, draft => {
        const accountRelationshipModified = draft.content.accountRelationships.find(
          r => r.account.id === accountRelationship.account.id
        );
        accountRelationshipModified.hasCarousel = linkedinCarousel;
        draft.content.linkedinCarousel = draft.content.accountRelationships.some(
          x => x.hasCarousel
        );
        if (!linkedinCarousel) {
          accountRelationshipModified.linkedinAdAccounts = [];
        }
      });
    }

    case "CHANGE_INSTAGRAM_BUSINESS_SETTING": {
      const { sendMobileReminder, instaReels } = action;

      if (!state.content) {
        return state;
      }

      // COMMENT SINCE WE'RE IMPLEMENTING INSTAGRAM CAROUSEL
      // if is setting to false and there are more of than 1 images in any
      // of the variations then we need to show a message
      // if (
      //   !sendMobileReminder &&
      //   state.content.variations.some(v => v.images.length > 1)
      // ) {
      //   return produce(state, draft => {
      //     draft.rejectionModalReasons.incompatibleAccountReasons = [
      //       "Instagram Direct does not support multiple images."
      //     ];
      //   });
      // }

      return produce(state, draft => {
        draft.content.sendMobileReminder = sendMobileReminder;
        draft.content.instaReels = instaReels;
      });
    }

    case "CHANGE_USE_ONCE": {
      const { useOnce } = action;
      if (!state.content) {
        return state;
      }

      return produce(state, draft => {
        draft.content.useOnce = useOnce;
      });
    }

    case "CHANGE_USE_SHORT_LINKS": {
      const { useShortLinks } = action;
      if (!state.content) {
        return state;
      }

      return produce(state, draft => {
        draft.content.useShortLinks = useShortLinks;
      });
    }

    case "CHANGE_EXPIRES_AT": {
      const { expiresAt } = action;
      if (!state.content) {
        return state;
      }

      return produce(state, draft => {
        draft.content.expiresAt = expiresAt;
      });
    }

    case "CHANGE_SEND_AT": {
      const { sendAt } = action;
      if (!state.content) {
        return state;
      }

      return produce(state, draft => {
        draft.content.sendAt = sendAt;
      });
    }

    case "APPROVE": {
      if (!state.content) {
        return state;
      }

      return produce(state, draft => {
        draft.content.status = "APPROVED";
      });
    }

    case "FOCUS_EDITOR": {
      const { idx, field } = action;

      return produce(state, draft => {
        draft.editorFocus.idx = idx;
        draft.editorFocus.field = field;
      });
    }

    case "BLUR_EDITOR": {
      return produce(state, draft => {
        draft.editorFocus.field = null;
      });
    }

    case "MOUSE_MOVE_EDITOR": {
      const { idx } = action;
      if (state.editorFocus.hovered === idx) {
        return state;
      }

      return produce(state, draft => {
        draft.editorFocus.hovered = idx;
      });
    }

    case "MOUSE_LEAVE_EDITOR": {
      const { idx } = action;
      if (state.editorFocus.hovered !== idx) {
        return state;
      }

      return produce(state, draft => {
        draft.editorFocus.hovered = null;
      });
    }

    case "CHANGE_PINTEREST_DESTINATION_LINK": {
      const { variationClientId, link } = action;
      if (!state.content) {
        return state;
      }
      const variationDoesNotExist = !state.content.variations.some(
        v => v.clientId === variationClientId
      );
      if (variationDoesNotExist) {
        return state;
      }

      return produce(state, draft => {
        const variation = draft.content.variations.find(
          v => v.clientId === variationClientId
        );
        variation.pinterestDestinationLink = link;
        maybeStartScrape(draft, variationClientId);
      });
    }

    case "CHANGE_PINTEREST_TITLE": {
      const { variationClientId, title } = action;
      if (!state.content) {
        return state;
      }
      const variationDoesNotExist = !state.content.variations.some(
        v => v.clientId === variationClientId
      );
      if (variationDoesNotExist) {
        return state;
      }

      return produce(state, draft => {
        const variation = draft.content.variations.find(
          v => v.clientId === variationClientId
        );
        variation.pinterestTitle = title;
      });
    }

    case "CHANGE_FB_TEXT": {
      const { variationClientId, fbText } = action;
      if (!state.content) {
        return state;
      }
      const variationDoesNotExist = !state.content.variations.some(
        v => v.clientId === variationClientId
      );
      if (variationDoesNotExist) {
        return state;
      }

      return produce(state, draft => {
        draft.hasModifiedVariations = true;
        const variation = draft.content.variations.find(
          v => v.clientId === variationClientId
        );
        variation.fbText = fbText;
      });
    }

    case "CHANGE_TEXT": {
      const { variationClientId, text } = action;
      if (!state.content) {
        return state;
      }
      const variationDoesNotExist = !state.content.variations.some(
        v => v.clientId === variationClientId
      );
      if (variationDoesNotExist) {
        return state;
      }

      return produce(state, draft => {
        draft.hasModifiedVariations = true;
        const variation = draft.content.variations.find(
          v => v.clientId === variationClientId
        );
        variation.text = text;
        maybeStartScrape(draft, variationClientId);
      });
    }

    case "CHANGE_ACCOUNT_TEXT": {
      const { variationClientId, accountId, text, removeFromUpdate } = action;
      console.log(
        "CHANGE_ACCOUNT_TEXT",
        variationClientId,
        accountId,
        text,
        removeFromUpdate
      );
      if (!state.content) {
        return state;
      }

      const variationDoesNotExist = !state.content.variations.some(
        v => v.clientId === variationClientId
      );
      if (variationDoesNotExist) {
        return state;
      }

      return produce(state, draft => {
        draft.hasModifiedVariations = true;
        const variation = draft.content.variations.find(
          v => v.clientId === variationClientId
        );
        // adding data for the first time if accountsData doesn't exist
        const accountsData = variation.accountsData;
        if (!accountsData && text !== undefined) {
          variation.accountsData = [];
          variation.accountsData.push({
            accountId,
            text
          });
          // remove data if text is undefined and removeFromUpdate is false
        } else if (text === undefined && !removeFromUpdate) {
          variation.accountsData = accountsData.filter(
            a => a.accountId !== accountId
          );
          // update data if text is defined
        } else {
          if (accountsData) {
            const accountData = accountsData.find(
              a => a.accountId === accountId
            );
            // add remove: true if removeFromUpdate is true
            if (removeFromUpdate && accountData) {
              accountData.remove = true;
            } else if (accountData) {
              accountData.text = text;
              // delete the remove property if it exists
              if (accountData.remove) delete accountData.remove;
            } else {
              variation.accountsData.push({
                accountId,
                text
              });
            }
          }
        }
        maybeStartScrape(draft, variationClientId);
      });
    }

    case "CHANGE_ENTITY_MAP": {
      const { variationClientId, rawRichTextEntityMap } = action;
      if (!state.content) {
        return state;
      }
      const variationDoesNotExist = !state.content.variations.some(
        v => v.clientId === variationClientId
      );
      if (variationDoesNotExist) {
        return state;
      }

      return produce(state, draft => {
        const variation = draft.content.variations.find(
          v => v.clientId === variationClientId
        );
        variation.rawRichTextEntityMap = rawRichTextEntityMap;
      });
    }

    case "ADD_VARIATION": {
      if (!state.content) {
        return state;
      }

      const lastVariation =
        state.content.variations[state.content.variations.length - 1];
      const nextFocusIdx = state.content.variations.length;
      const clientId = nanoid();

      return produce(state, draft => {
        draft.hasModifiedVariations = true;
        draft.content.variations.push({
          id: null,
          clientId,
          text: "",
          link: lastVariation.link,
          pinterestDestinationLink: lastVariation.pinterestDestinationLink,
          origin: "MANUAL",
          images: [],
          videos: [],
          accountsData: []
        });
        draft.editorFocus.idx = nextFocusIdx;
        draft.editorFocus.field = "text";
        draft.scrapes[clientId] = { ...draft.scrapes[lastVariation.clientId] };
      });
    }

    case "DELETE_VARIATION": {
      const { variationClientId } = action;
      if (!state.content) {
        return state;
      }

      return produce(state, draft => {
        draft.hasModifiedVariations = true;
        draft.content.variations = draft.content.variations.filter(
          v => v.clientId !== variationClientId
        );
        draft.editorFocus.idx = draft.content.variations.length - 1;
      });
    }

    case "ADD_SUGGESTED_VARIATIONS": {
      const { suggestions } = action;
      if (!state.content) {
        return state;
      }

      // Filter out duplicates that may already exist
      const existingTexts = state.content.variations.map(v => v.text);
      const newSuggestions = suggestions.filter(
        s => !existingTexts.includes(s.text)
      );
      if (newSuggestions.length === 0) {
        return produce(state, draft => {
          draft.variationSuggestions.status = "FAILED";
        });
      }

      //TODO: Do we set links here or not?
      // Check for Facebook/LinkedIn and Pinterest
      const nextFocusIdx = state.content?.variations?.length;

      return produce(state, draft => {
        draft.variationSuggestions.status = "COMPLETE";
        draft.hasModifiedVariations = true;
        suggestions.forEach(({ url: link, text }) => {
          const clientId = nanoid();
          draft.content.variations.push({
            id: null,
            clientId,
            text,
            link,
            pinterestDestinationLink: link,
            origin: "AUTOGENERATED",
            images: [],
            videos: []
          });
          maybeStartScrape(draft, clientId);
        });
        draft.editorFocus.idx = nextFocusIdx;
        draft.editorFocus.field = "text";
      });
    }

    case "DISMISS_SUGGESTED_VARIATIONS_FAILED": {
      return produce(state, draft => {
        draft.variationSuggestions.status = "COMPLETE";
      });
    }

    case "IMAGE_UPLOAD_PROGRESS": {
      const { variationClientId, fileName, progress } = action;
      return produce(state, draft => {
        draft.uploadingMedia[variationClientId] =
          draft.uploadingMedia[variationClientId] || [];
        const existing = draft.uploadingMedia[variationClientId].find(
          u => u.fileName === fileName
        );
        if (existing) {
          existing.progress = progress;
        } else {
          draft.uploadingMedia[variationClientId].push({ fileName, progress });
        }
      });
    }

    case "IMAGE_UPLOAD_ERROR": {
      const { variationClientId, fileName, messages } = action;
      return produce(state, draft => {
        draft.rejectionModalReasons.incompatibleMediaReasons[
          fileName
        ] = messages;
        draft.uploadingMedia[variationClientId] = (
          draft.uploadingMedia[variationClientId] ?? []
        ).filter(p => p.fileName !== fileName);
      });
    }

    case "IMAGE_UPLOAD_FINISH": {
      const { variationClientId, fileName, image } = action;
      return produce(state, draft => {
        const variation = draft.content.variations.find(
          v => v.clientId === variationClientId
        );
        const imageDoesNotAlreadyExist = !variation.images.some(
          i => i.clientProvidedSha256 === image.clientProvidedSha256
        );
        if (imageDoesNotAlreadyExist) {
          variation.images.push(image);
        }
        draft.uploadingMedia[variationClientId] = (
          draft.uploadingMedia[variationClientId] ?? []
        ).filter(p => p.fileName !== fileName);
      });
    }

    case "CHANGE_IMAGE_ORDER_ACTION": {
      const { variationClientId } = action;

      return produce(state, draft => {
        const variation = draft.content.variations.find(
          v => v.clientId === variationClientId
        );

        if (action.type === "CHANGE_IMAGE_ORDER_ACTION") {
          let position = 1;
          variation.images = action.images.map(item => ({
            ...item,
            position: position++
          }));
        }
      });
    }

    case "CHANGE_IMAGE_TEXT_ACTION": {
      const { variationClientId } = action;

      return produce(state, draft => {
        const variation = draft.content.variations.find(
          v => v.clientId === variationClientId
        );
        function getAlternateText(imageId) {
          return variation.images.find(x => x.id == imageId)?.text || "";
        }
        variation.images = action.images.map(item => ({
          ...item
        }));
        variation.imagesTextVariations?.forEach(x => {
          x.text = getAlternateText(x.imageId) || x.text;
        });
      });
    }
    case "REMOVE_IMAGE": {
      const { variationClientId, imageId } = action;
      return produce(state, draft => {
        const variation = draft.content.variations.find(
          v => v.clientId === variationClientId
        );
        variation.images = variation.images.filter(i => i.id !== imageId);
      });
    }

    case "VIDEO_UPLOAD_PROGRESS": {
      const { variationClientId, fileName, progress } = action;
      return produce(state, draft => {
        draft.uploadingMedia[variationClientId] =
          draft.uploadingMedia[variationClientId] || [];
        const existing = draft.uploadingMedia[variationClientId].find(
          u => u.fileName === fileName
        );
        if (existing) {
          existing.progress = progress;
        } else {
          draft.uploadingMedia[variationClientId].push({ fileName, progress });
        }
      });
    }

    case "VIDEO_UPLOAD_ERROR": {
      const { variationClientId, fileName, messages } = action;
      return produce(state, draft => {
        draft.rejectionModalReasons.incompatibleMediaReasons[
          fileName
        ] = messages;
        draft.uploadingMedia[variationClientId] = (
          draft.uploadingMedia[variationClientId] ?? []
        ).filter(p => p.fileName !== fileName);
      });
    }

    case "VIDEO_UPLOAD_FINISH": {
      const { variationClientId, fileName, video } = action;
      return produce(state, draft => {
        const variation = draft.content.variations.find(
          v => v.clientId === variationClientId
        );
        const videoDoesNotAlreadyExist = !variation.videos.some(
          v => v.clientProvidedSha256 === video.clientProvidedSha256
        );
        if (videoDoesNotAlreadyExist) {
          variation.videos.push(video);
        }
        draft.uploadingMedia[variationClientId] = (
          draft.uploadingMedia[variationClientId] ?? []
        ).filter(p => p.fileName !== fileName);
      });
    }

    case "REMOVE_VIDEO": {
      const { variationClientId, videoId } = action;
      return produce(state, draft => {
        const variation = draft.content.variations.find(
          v => v.clientId === variationClientId
        );
        variation.videos = variation.videos.filter(i => i.id !== videoId);
      });
    }

    case "ATTACH_VIDEO": {
      const { variationClientId, video } = action;

      return produce(state, draft => {
        const variation = draft.content.variations.find(
          v => v.clientId === variationClientId
        );
        const videoDoesNotAlreadyExist = !variation.videos.some(
          v => v.clientProvidedSha256 === video.clientProvidedSha256
        );
        if (videoDoesNotAlreadyExist) {
          variation.videos.push(video);
        }
      });
    }

    case "ATTACH_IMAGE": {
      const { variationClientId, image } = action;
      return produce(state, draft => {
        const variation = draft.content.variations.find(
          v => v.clientId === variationClientId
        );
        const imageDoesNotAlreadyExist = !variation.images.some(
          i => i.clientProvidedSha256 === image.clientProvidedSha256
        );
        if (imageDoesNotAlreadyExist) {
          variation.images.push(image);
        }
      });
    }

    case "ATTACH_SCRAPED_IMAGE_START": {
      const { variationClientId } = action;

      return produce(state, draft => {
        draft.attachingImage[variationClientId] = true;
      });
    }

    case "ATTACH_SCRAPED_IMAGE_FINISH": {
      const { variationClientId, image } = action;

      return produce(state, draft => {
        const variation = draft.content.variations.find(
          v => v.clientId === variationClientId
        );
        const imageDoesNotAlreadyExist = !variation.images.some(
          i => i.clientProvidedSha256 === image.clientProvidedSha256
        );
        if (imageDoesNotAlreadyExist) {
          variation.images.push(image);
        }
        draft.attachingImage[variationClientId] = false;
      });
    }

    case "SET_MENTION_SUGGESTIONS": {
      const { suggestions } = action;

      return produce(state, draft => {
        draft.mentionSuggestions = suggestions;
      });
    }

    case "DISMISS_REJECTION_MODAL": {
      return produce(state, draft => {
        draft.rejectionModalReasons = initialState.rejectionModalReasons;
      });
    }

    case "START_PERSIST": {
      return produce(state, draft => {
        draft.serverErrors = {};
      });
    }

    case "FINISH_PERSIST": {
      return produce(state, draft => {
        draft.persistResult = null;
      });
    }

    case "UPDATE_CONTENT_FINISH": {
      const { content, errors } = action;
      const serverErrors = validateServer(errors);
      return produce(state, draft => {
        if (errors.length === 0) {
          draft.content = content;
          draft.serverErrors = {};
          draft.hasModifiedVariations = false;
          draft.scrapes = {};
          draft.variationSuggestions.status = null;
          draft.persistResult = "UPDATED";
          return;
        }
        draft.persistResult = "ERROR";
        draft.serverErrors = serverErrors;
        if (serverErrors.base || serverErrors.send_at) {
          draft.rejectionModalReasons.failedValidationReasons = (
            serverErrors.base || []
          ).concat(serverErrors.send_at || []);
        }
      });
    }

    case "CREATE_CONTENT_FINISH": {
      const { content, errors, hasAccountWithoutSchedule } = action;
      const serverErrors = validateServer(errors);
      return produce(state, draft => {
        if (errors.length === 0 && content) {
          draft.content = {
            ...initialState.content,
            category: content.category,
            accountRelationships: content.accountRelationships
          };
          draft.hasAccountWithoutSchedule = hasAccountWithoutSchedule;
          draft.serverErrors = {};
          draft.hasModifiedVariations = false;
          draft.scrapes = {};
          draft.variationSuggestions.status = null;
          draft.persistResult = "CREATED";
          return;
        }
        draft.persistResult = "ERROR";
        draft.serverErrors = serverErrors;
        if (serverErrors.base || serverErrors.send_at) {
          draft.rejectionModalReasons.failedValidationReasons = (
            serverErrors.base || []
          ).concat(serverErrors.send_at || []);
        } else if (serverErrors.variations) {
          const messages = errors.map(e => {
            if (e.field.includes("variations") && e.messages.length > 0) {
              const varNumber = e.field.split("[")[1].split("]")[0];
              if (varNumber.match(/^[0-9]+$/)) {
                return (
                  "Error with variation " + varNumber + ": " + e.messages[0]
                );
              } else {
                return "Error with variation: " + e.messages[0];
              }
            }
          });

          if (messages.length > 0) {
            draft.rejectionModalReasons.failedValidationReasons = messages;
          }
        }
      });
    }

    case "COMPLETE_ONBOARDING_TOOLTIP_STEP": {
      const { step } = action;
      return produce(state, draft => {
        switch (step) {
          case "categories": {
            draft.onboardingTooltipStep = "accounts";
            break;
          }
          case "accounts": {
            draft.onboardingTooltipStep = "textfield";
            break;
          }
          case "textfield": {
            draft.onboardingTooltipStep = "add-variation";
            break;
          }
          case "add-variation": {
            draft.onboardingTooltipStep = "save";
            break;
          }
          case "save": {
            draft.onboardingTooltipStep = null;
            break;
          }
        }
      });
    }

    case "OPEN_SEND_AT_MODAL": {
      return produce(state, draft => {
        draft.isSetSendAtModalOpen = true;
      });
    }

    case "CLOSE_SEND_AT_MODAL": {
      return produce(state, draft => {
        draft.isSetSendAtModalOpen = false;
      });
    }

    case "START_SCRAPE": {
      const { variationClientId } = action;
      if (!state.content) {
        return state;
      }

      const variation = state.content.variations.find(
        v => v.clientId === variationClientId
      );
      if (!variation) {
        return state;
      }

      const { text, link, pinterestDestinationLink } = variation;
      const urlsInText = extractUrlsWithIndices(text).map(({ url }) => url);
      const urlToScrape = urlsInText?.[0] || link || pinterestDestinationLink;

      if (!urlToScrape || !isValidUrl(urlToScrape)) {
        return state;
      }

      return produce(state, draft => {
        draft.scrapes[variationClientId] = {
          status: "STARTED",
          url: urlToScrape,
          pageScrape: {
            status: "waiting",
            url: urlToScrape
          }
        };
      });
    }

    case "POLL_SCRAPE": {
      const { variationClientId, timeoutId } = action;
      return produce(state, draft => {
        draft.scrapes[variationClientId].status = "POLLING";
        draft.scrapes[variationClientId].timeoutId = timeoutId;
      });
    }

    case "SCRAPE_SUCCEEDED": {
      const { variationClientId, pageScrape } = action;
      return produce(state, draft => {
        draft.scrapes[variationClientId].status = "SUCCEEDED";
        draft.scrapes[variationClientId].pageScrape = pageScrape;
        draft.scrapes[variationClientId].errors = [];
      });
    }

    case "SCRAPE_FAILED": {
      const { variationClientId, pageScrape, errors } = action;
      return produce(state, draft => {
        draft.scrapes[variationClientId].status = "FAILED";
        draft.scrapes[variationClientId].pageScrape = pageScrape;
        draft.scrapes[variationClientId].errors = errors;
      });
    }

    case "REMOVE_LINK": {
      const { variationClientId } = action;

      return produce(state, draft => {
        draft.linkAttachmentRemoved[variationClientId] = true;
      });
    }

    case "ATTACH_LINK": {
      const { variationClientId } = action;

      return produce(state, draft => {
        draft.linkAttachmentRemoved[variationClientId] = false;
      });
    }

    default: {
      return state;
    }
  }
};
