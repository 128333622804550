// @flow
import React, { useEffect, useState } from "react";
import classNames from "classnames";
import pluralize from "pluralize";

import Icon from "icons/Icon";

import { hasLinkAttachment } from "../util.js";
import Title from "./Title";
import TextField from "./TextField";
import FbTextField from "./FbTextField";
import Attachments from "./Attachments";
import ImageAttachmentButton from "./ImageAttachmentButton";
import VideoAttachmentButton from "./VideoAttachmentButton";
import CanvaButton from "./CanvaButton";
import PostPreview from "./PostPreview";
import styles from "./index.css";
import { Button } from "buttons";

import type { Props } from "./types.js";
import { MediaButtons } from "./styles";

import SelectedAccounts from "./SelectedAccounts";

import AIGenerator from "./TextField/AIGenerator/index.js";

export const Variation = ({
  content,
  value,
  title,
  isWidget,
  focused,
  focusedField,
  hovered,
  deleteVisible,
  fbTextVisible,
  isConnectAccountsModalOpen,
  remainingCharacterCounts,
  selectedPlatforms,
  selectedProviders,
  selectedAccounts,
  selectedPinterestBoardsByAccount,
  mentionSuggestions,
  uploadingMedia,
  allAttachedVideos,
  allAttachedImages,
  attachingImage,
  pageScrape,
  requiredFieldErrorVisible,
  linkAttachmentRemoved,
  warnings,
  clientErrors,
  serverErrors,
  onNextOnboardingStep,
  showOnboardingTooltip,
  onClearMentionSuggestions,
  onAddMention,
  onMouseMove,
  onMouseLeave,
  onFocusText,
  onFocusFbText,
  onFocusVariation,
  onBlurField,
  onChangeText,
  onChangeAccountText,
  onChangeFbText,
  onEntityMapChange,
  onSearchSocialMedia,
  onMentionSuggestionWarning,
  onDelete,
  onAttachImage,
  onAttachScrapedImage,
  onImageUploadStart,
  onImageUploadProgress,
  onImageUploadError,
  onImageUploadValidationError,
  onImageUploadFinish,
  onImagesOrderChange,
  onImagesTitleChange,
  onRemoveImage,
  onAttachVideo,
  onVideoUploadStart,
  onVideoUploadProgress,
  onVideoUploadError,
  onVideoUploadValidationError,
  onVideoUploadFinish,
  onRemoveVideo,
  onEnableLinkPreview,
  onDisableLinkPreview,
  onChangePinterestDestinationLink,
  onChangePinterestTitle,
  setOnDragEndMethod,
  showAIGenerator,
  toggleAIGenerator
}: Props) => {
  const lastField = fbTextVisible ? "fbText" : "text";
  const displayAsHovered = hovered && !focused;
  const rootClasses = classNames(
    {},
    {
      [styles.hovered]: displayAsHovered
    }
  );
  const textClasses = classNames(styles.field, styles.firstField, {
    [styles.lastField]: lastField === "text"
  });
  const variationTextFieldName = `variationText${value.clientId}`;
  const variationFbTextFieldName = `variationFbText${value.clientId}`;

  const textErrors = (clientErrors?.text || []).concat(
    serverErrors?.text ?? []
  );
  const fbTextErrors = (clientErrors?.fbText || []).concat(
    serverErrors?.fbText ?? []
  );

  const hasErrors = !!textErrors.length || !!fbTextErrors.length;
  const hasWarnings = !!warnings.length;

  const linkAttached = hasLinkAttachment(
    value,
    selectedPlatforms,
    pageScrape,
    uploadingMedia,
    linkAttachmentRemoved
  );
  const [previewModalIsShown, setPreviewModalIsShown] = useState(false);
  const handlePreviewButtonClick = () => {
    previewModalIsShown
      ? setPreviewModalIsShown(false)
      : setPreviewModalIsShown(true);
  };

  let handleOnDragEndMethod = () => { };

  useEffect(() => {
    setOnDragEndMethod(handleOnDragEndMethod);
  }, [setOnDragEndMethod]);

  const setHandleOnDragEndMethod = newMethod => {
    handleOnDragEndMethod = newMethod;
  };

  const itHasTikTok = selectedAccounts.some(
    account => account.provider === "TIKTOK"
  );

  return (
    <div
      onClick={onFocusVariation}
      className={rootClasses}
      onMouseMove={onMouseMove}
      onMouseLeave={onMouseLeave}
    >
      {focused && (<>
        <Title
          htmlFor={variationTextFieldName}
          remainingCharacterCounts={
            !fbTextVisible ? remainingCharacterCounts : null
          }
          warnings={warnings}
          hasErrors={hasErrors}
          hasRequiredFieldError={requiredFieldErrorVisible}
          deleteVisible={deleteVisible}
          focused={focused}
          hovered={displayAsHovered}
          fbTextVisible={fbTextVisible}
          remainingCharacterCounts={remainingCharacterCounts}
          twitterPosted={!!value?.lastPostedTo?.twitter}
          onDelete={onDelete}
          previewButtonVisible={deleteVisible} // we want the behavior to match that of the delete button
          handlePreviewButtonClick={handlePreviewButtonClick}
        >
          {title}
        </Title>
        <div className={styles.fields}>
          <div
            className={
              showAIGenerator ? styles.fieldElementAi : styles.fieldElement
            }
          >
            <TextField
              value={value}
              className={textClasses}
              fieldName={variationTextFieldName}
              focused={focused}
              fieldFocused={focusedField === "text"}
              fbTextVisible={fbTextVisible}
              isWidget={isWidget}
              hasErrors={hasErrors}
              hasWarnings={hasWarnings}
              isConnectAccountsModalOpen={isConnectAccountsModalOpen}
              selectedPlatforms={selectedPlatforms}
              selectedProviders={selectedProviders}
              mentionSuggestions={mentionSuggestions}
              remainingCharacterCounts={
                fbTextVisible ? remainingCharacterCounts : null
              }
              errors={textErrors}
              onNextOnboardingStep={onNextOnboardingStep}
              showOnboardingTooltip={showOnboardingTooltip}
              onAddMention={onAddMention}
              onClearMentionSuggestions={onClearMentionSuggestions}
              onChange={onChangeText.bind(null, value.clientId)}
              onEntityMapChange={onEntityMapChange.bind(null, value.clientId)}
              onSearchSocialMedia={onSearchSocialMedia}
              onMentionSuggestionWarning={onMentionSuggestionWarning}
              onFocus={onFocusText}
              onBlur={onBlurField}
            />
            <FbTextField
              className={classNames(styles.field, styles.lastField)}
              fieldName={variationFbTextFieldName}
              selectedProviders={selectedProviders}
              mentionSuggestions={mentionSuggestions}
              visible={fbTextVisible}
              isWidget={isWidget}
              hasErrors={hasErrors}
              hasWarnings={hasWarnings}
              focused={focused}
              fieldFocused={focusedField === "fbText"}
              value={value}
              errors={fbTextErrors}
              onClearMentionSuggestions={onClearMentionSuggestions}
              onAddMention={onAddMention}
              onChange={onChangeFbText.bind(null, value.clientId)}
              onEntityMapChange={onEntityMapChange.bind(null, value.clientId)}
              onSearchSocialMedia={onSearchSocialMedia}
              onMentionSuggestionWarning={onMentionSuggestionWarning}
              onFocus={onFocusFbText}
              onBlur={onBlurField}
            />
          </div>
          {
            showAIGenerator && (
              <div className={styles.fieldElementAi}>
                <AIGenerator
                  toggleAIGenerator={toggleAIGenerator}
                  showAIGenerator={showAIGenerator}
                  selectedPlatforms={selectedPlatforms}
                  selectedProviders={selectedProviders}
                />
              </div>
            )
          }
        </div >
        {itHasTikTok && (
          <div className={styles.tiktokHelper}>
            Use the TikTok app to write text for scheduled TikTok.
          </div>
        )}
        {
          focused ? (
            <div>
              <MediaButtons>
                <div>
                  <ImageAttachmentButton
                    variation={value}
                    sendMobileReminder={content.sendMobileReminder}
                    instaReels={content.instaReels}
                    selectedPlatforms={selectedPlatforms}
                    selectedProviders={selectedProviders}
                    linkAttached={linkAttached}
                    onStart={onImageUploadStart}
                    onProgress={onImageUploadProgress}
                    onUploadError={onImageUploadError}
                    onValidationError={onImageUploadValidationError}
                    onFinish={onImageUploadFinish}
                    onImagesOrderChange={onImagesOrderChange}
                  />
                  <VideoAttachmentButton
                    variation={value}
                    selectedPlatforms={selectedPlatforms}
                    selectedProviders={selectedProviders}
                    linkAttached={linkAttached}
                    uploadingMedia={uploadingMedia.length > 0}
                    onStart={onVideoUploadStart}
                    onProgress={onVideoUploadProgress}
                    onUploadError={onVideoUploadError}
                    onValidationError={onVideoUploadValidationError}
                    onFinish={onVideoUploadFinish}
                    instaReels={content.instaReels}
                    linkedinCarousel={content.linkedinCarousel}
                  />
                  <CanvaButton />
                </div>
                {!showAIGenerator && (
                  <Button type="primary" onClick={toggleAIGenerator}>
                    Generate Content with Inky
                  </Button>
                )}
              </MediaButtons>
              <Attachments
                setOnDragEndMethod={setHandleOnDragEndMethod}
                images={value.images}
                imagesTextVariations={value.imagesTextVariations}
                videos={value.videos}
                sendMobileReminder={content.sendMobileReminder}
                instaReels={content.instaReels}
                pinterestTitle={value.pinterestTitle}
                pinterestDestinationLink={value.pinterestDestinationLink}
                linkAttached={linkAttached}
                pageScrape={pageScrape}
                selectedPlatforms={selectedPlatforms}
                selectedProviders={selectedProviders}
                uploadingMedia={uploadingMedia}
                allAttachedVideos={allAttachedVideos}
                allAttachedImages={allAttachedImages}
                attachingImage={attachingImage}
                clientErrors={clientErrors}
                onAttachImage={onAttachImage.bind(null, value.clientId)}
                onImagesOrderChange={onImagesOrderChange.bind(null, value.clientId)}
                onAttachScrapedImage={onAttachScrapedImage.bind(
                  null,
                  value.clientId
                )}
                onRemoveImage={onRemoveImage.bind(null, value.clientId)}
                onRemoveVideo={onRemoveVideo.bind(null, value.clientId)}
                onAttachVideo={onAttachVideo.bind(null, value.clientId)}
                onEnableLinkPreview={onEnableLinkPreview.bind(null, value.clientId)}
                onDisableLinkPreview={onDisableLinkPreview.bind(
                  null,
                  value.clientId
                )}
                onChangePinterestDestinationLink={onChangePinterestDestinationLink}
                onChangePinterestTitle={onChangePinterestTitle}
                variationId={value.clientId}
                onImageUploadValidationError={onImageUploadValidationError}
                linkedinCarousel={content.linkedinCarousel}
                onImagesTitleChange={onImagesTitleChange.bind(null, value.clientId)}
              />
            </div>
          ) : (
            <div className={styles.mediaCounts}>
              {value.images.length > 0 && (
                <span>
                  <Icon type="image" /> {value.images.length}{" "}
                  {pluralize("image", value.images.length)} attached
                </span>
              )}
              {value.videos.length > 0 && (
                <span>
                  <Icon type="film" /> {value.videos.length}{" "}
                  {pluralize("video", value.videos.length)} attached
                </span>
              )}
              {linkAttached && (
                <span>
                  <Icon type="link" /> link preview attached
                </span>
              )}
            </div>

          )
        }

        {
          previewModalIsShown && (
            <PostPreview
              closeButtonClicked={handlePreviewButtonClick}
              selectedAccounts={selectedAccounts}
              selectedPinterestBoardsByAccount={selectedPinterestBoardsByAccount}
              content={content}
              variationData={value}
              title
              mentionSuggestions={mentionSuggestions}
              uploadingMedia={uploadingMedia.length > 0}
              attachingImage={attachingImage}
              pageScrape={pageScrape}
              selectedPlatforms={selectedPlatforms}
            />
          )
        }

        <SelectedAccounts
          editing={!!content.id}
          variation={value}
          accounts={selectedAccounts}
          onChangeAccountText={(accountId, text, removeFromUpdate) =>
            onChangeAccountText(value.clientId, accountId, text, removeFromUpdate)
          }
        />
      </>)}
    </div >
  );
};

export default Variation;
