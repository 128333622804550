/* @flow */
import styled from "styled-components";

const widthBreakpoint = 500;

export const MediaButtons = styled.div`
  margin: 20px 0 0 0px;
  /* position: absolute; */

  @media (min-width: ${widthBreakpoint}px) {
    align-items: end;
    justify-content: space-between;
    display: flex;
  }
`;
